<template>
    <div>
        <div v-if="verifyEmail">
            <h4>Verifica indirizzo email:</h4>
            <p>
                Clicca il pulsante "Invia Email". Riceverai nella casella di posta <b v-text="defaultEmail"></b> un messaggio contenente un codice alfanumerico.<br>
                Dunque copia e incolla il codice nel campo "Codice Email" sulla destra.
                <br>
                <br>
            </p>
            <transition name="fade">
                <div v-show="showEmailError" class="error-block">
                    <span v-text="'Errore: '+emailErrorText"></span>
                </div>
            </transition>
            <transition name="fade">
                <div class="form-row auth-form-row auth-form-verified" v-if="emailVerified">
                    <p>Indirizzo email verificato!</p>
                </div>
                <div class="form-row auth-form-row" v-else>
                    <div class="form-group">
                        <transition name="fade">
                            <div class="form-input" v-if="showVerifyEmailButton">
                                <label>Clicca qui:</label>
                                <button type="button" class="submit btn-small" id="submit1" @click="doVerifyEmail">Invia Email</button>
                            </div>
                            <div class="form-input" v-else>
                                <br>Email inviata - <i @click="showVerifyEmailButton = true" style="cursor:pointer">Riprova.</i>
                            </div>
                        </transition>
                    </div>
                    <div class="form-group">
                        <div class="form-input">
                            <label for="email_code" class="required">Codice Email</label>
                            <input id="email_code" type="text" name="email_code" required autofocus placeholder="Codice di verifica" v-model="emailCode" style="max-width: 90%;" @keyup="checkEmailCode"/>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="verifyPhone">
            <h4>Verifica telefono mobile:</h4>
            <p>
                Clicca il pulsante "Invia SMS". Riceverai un sms al numero indicato contenente un codice alfanumerico.<br>
                Dunque copia e incolla il codice nel campo "Codice Telefono" sulla destra.
                <br>
                <br>
            </p>
            <transition name="fade">
                <div v-show="showPhoneError" class="error-block">
                    <span v-text="'Errore: '+phoneErrorText"></span>
                </div>
            </transition>
            <transition name="fade">
                <div class="form-row auth-form-row auth-form-verified" v-if="phoneVerified">
                    <p>Numero di telefono verificato!</p>
                </div>
                <div class="form-row auth-form-row" v-else>
                    <transition name="fade">
                        <div class="form-group" v-if="showVerifyPhoneButton">
                            <div class="form-input" style="margin-bottom:6px">
                                <label for="phone" class="required">Numero di telefono</label>
                                <input id="phone" type="text" name="phone" required autofocus placeholder="+39 000 0000000" v-model="phoneNumber" style="max-width: 90%;" />
                            </div>
                            <div class="form-input">
                                <label>Clicca qui:</label>
                                <button type="button" class="submit btn-small" id="submit2" @click="doVerifyPhone">Invia SMS</button>
                            </div>
                        </div>
                        <div class="form-group" v-else>
                            <br>SMS inviato - <i @click="showVerifyPhoneButton = true" style="cursor:pointer">Riprova.</i>
                        </div>
                    </transition>
                    <div class="form-group">
                        <div class="form-input">
                            <label for="phone_code" class="required">Codice SMS</label>
                            <input id="phone_code" type="text" name="phone_code" required autofocus placeholder="Codice di verifica" v-model="phoneCode" style="max-width: 90%;" @keyup="checkPhoneCode" />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        // setTimeout(() => {
        //     this.checkAllVerified();
        // }, 1000)
    },
    props: {
        verifyEmail: { required: true},
        verifyPhone: { required: true},
        preEmailVerified: { required: true},
        prePhoneVerified: { required: true},
        defaultEmail: { required: true},
        defaultPhone: { required: true},
        redirect: { required: true }
    },
    data() {
        return {
            phoneNumber: this.defaultPhone,
            emailAddess: this.defaultEmail,
            emailVerified: this.preEmailVerified,
            phoneVerified: this.prePhoneVerified,
            showVerifyEmailButton: true,
            showVerifyPhoneButton: true,
            showEmailError: false,
            showPhoneError: false,
            emailErrorText: '',
            phoneErrorText: '',
            emailCode: '',
            phoneCode: ''
        }
    },
    methods: {
        doShowEmailError(text) {
            this.emailErrorText = text;
            this.showEmailError = true;
            setTimeout(()=> { this.showEmailError = false; }, 4000)
        },
        doShowPhoneError(text) {
            this.phoneErrorText = text;
            this.showPhoneError = true;
            setTimeout(()=> { this.showPhoneError = false; }, 4000)
        },
        doVerifyEmail() {
            axios.post('/email-verification', {})
            .then(res => {
                if (!res.data.success) {
                    this.doShowEmailError(res.data.message);
                }
                this.showVerifyEmailButton = false;
            })
        },
        doVerifyPhone() {
            let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            let matches = regex.exec(this.phoneNumber);
            if (matches === null || matches === undefined || matches === false) {
                this.doShowPhoneError('Numero di telefono non valido. Riprova.');
                return;
            }
            axios.post('/phone-verification', {
                phone_number: this.phoneNumber
            }).then(res => {
                if (!res.data.success) {
                    this.doShowPhoneError(res.data.message);
                }
                this.showVerifyPhoneButton = false;
            })
        },
        checkEmailCode() {
            if(this.emailCode.length >= 5) {
                axios.post('/check-verification-code', {
                    email_phone: 'email',
                    code: this.emailCode
                }).then(res => {
                    if (!res.data.success) {
                        this.doShowEmailError(res.data.message);
                    } else {
                        this.emailVerified = true;
                        this.checkAllVerified()
                    }
                })
            }
        },
        checkPhoneCode() {
            if(this.phoneCode.length >= 5) {
                axios.post('/check-verification-code', {
                    email_phone: 'phone',
                    code: this.phoneCode
                }).then(res => {
                    if (!res.data.success) {
                        this.doShowPhoneError(res.data.message);
                    } else {
                        this.phoneVerified = true;
                        this.checkAllVerified()
                    }
                })
            }
        },
        checkAllVerified() {
            if (this.verifyEmail && this.emailVerified && this.verifyPhone && this.phoneVerified) {
                window.location.href = this.redirect
            } else {
                if (this.verifyEmail && this.emailVerified) {
                    window.location.href = this.redirect
                }
                if (this.verifyPhone && this.phoneVerified) {
                    window.location.href = this.redirect
                }
            }

        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>